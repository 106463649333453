@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/breakpoints' as *;
 
.modal-thanks-content{
    padding:2rem;

    h1{
        text-align:center;
        margin-top:0;
    }
}