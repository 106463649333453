@use './assets/styles/_layout.scss' as *;

.app {
    // text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .app-content{
        z-index:0;
    }
}

