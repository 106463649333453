@use '../../assets/styles/_layout.scss' as *;
@use '../../assets/styles/breakpoints' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/colors' as *;

.screen-error{
    min-height: 1vh;
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $col-pink-plaster;
    
    .pic-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        opacity: 1;
        transition: opacity 1s;
    }
    .pic{
        width: 15rem;
        height: 15rem;
        align-self: center;
        text-align: center;

        -webkit-animation-duration: 3s;
        animation-duration: 3s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        transform-origin: 50% 100%;
        
        img{
            width: 100%;
            height: 100%;
        }
    }

    .pic:hover{
        -webkit-animation-name: wobble; 
        animation-name: wobble; 
    }

    h1{
        text-align: center;
        margin-top: 0;
        margin-bottom: 0;
    }
    p{
        text-align: center;
    }
}

.screen-error.loading{
    .pic-wrapper{
        opacity: 0;
    }
}

@-webkit-keyframes wobble { 
    // 0% { -webkit-transform: translateX(-25%) rotate(-5deg); } 
    // 30% { -webkit-transform: translateX(20%) rotate(3deg); } 
    // 45% { -webkit-transform: translateX(-15%) rotate(-3deg); } 
    // 60% { -webkit-transform: translateX(10%) rotate(2deg); } 
    // 100% { -webkit-transform: translateX(-5%) rotate(-1deg); }
    
 }
 
 @keyframes wobble { 
    // 0% { -webkit-transform: translateX(-25%) rotate(-5deg); } 
    0% { -webkit-transform: rotate(0deg); } 
    20% { -webkit-transform: rotate(-4deg); } 
    40% { -webkit-transform: rotate(7deg); } 
    60% { -webkit-transform: rotate(-4deg); }
    80% { -webkit-transform: rotate(1deg); }
    100% { -webkit-transform: rotate(0deg); }
 }
