@use '../../assets/styles/_breakpoints.scss' as *;
@use '../../assets/styles/_vars.scss' as *;
@use '../../assets/styles/_colors.scss' as *;

footer{
    position: relative;
    // bottom:0;
    width:100%;
    // height: 3rem;
    background-color: $col-bg;
    // padding:2rem;
    // padding:1rem;
    text-align:center;
    .content{
        opacity: 0.7;
        p{
            padding:1rem;
            font-size:$font-size-body-xs;
            color:$col-dark;
            transition:all 0.3s;

            .heart{
                color: $col-pink-plaster;
            }
            button{
                border: none;
                background-color: transparent;
                outline: none;
                color:$col-dark;
                padding:0;
                border-bottom:1px solid $col-dark-wraith;
                cursor:pointer;
                transition:all 0.3s;
                font-size:$font-size-body-xs;
            }
            button:hover{
                background-color: $col-yellow;
                color:$col-dark;
                border-bottom:1px solid $col-dark;
            }
        }
        
    }
    .content:hover{
        opacity: 1;
    }
    
    
}