@use '../../assets/styles/breakpoints' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/colors' as *;


#home{
    display:flex;
    flex-direction: column;
    justify-content: center;
    background-color: $col-pink-plaster;
    position:relative;
    height: 100vh;
    width: 100%;
    min-height: 35rem;
    
    h1{
        text-align: center;
        color:$col-text;
        margin: 0;
        margin-top: 1rem;
        z-index:100;
    }

    h2{
        color:$col-text;
    }

    .oval{
        position: absolute;
        // background-color: red;
        width: 100%;
        height: calc(100% + 30vh);
        top:-20vh;
        z-index:0;
        background-image: radial-gradient($col-pink-plaster, transparent);
        opacity:1;
    }
    
    .content{   
        // padding:3rem;
        display:flex;
        flex-direction: column;
        align-content: center;
        transition:all 1s;
        margin-top: -10vh;
        // transform: translateY(100px);
        z-index:100;

        
    }

    .poll{
        // margin-top:3rem;
        z-index:100;
    }

    
}


#home.hidden .content{
    opacity: 0;
}
#home.revealed .content{
    opacity: 1;
    // transform: translateY(0px);
}