@use '../../assets/styles/colors' as *;

.images{
    // position: relative;
    max-width:100%;
    // margin-bottom:3rem;
    // position: relative;
    // overflow:hidden; 
    .image{
        position: relative;
        background-color: transparent;
        margin-bottom: 3rem;
        overflow:hidden;
        cursor:pointer;

        .bg{
            position:absolute;
            width: 100%;
            height: 100%;
            transition: all 0.3s;
            opacity: 0.03;
        }
        img{
            position: absolute;
            top:0;
            left: 0;
            width:100%;
            height: 100%;
            transition: all 0.3s;
            opacity:0;
        }   
        img:hover{ 
            transform:scale(1.01);
        }
    }
    .image.revealed {
        .bg{
            opacity: 1;
        }
        img{
            opacity: 1;
        }
    }
    
    .image.unrevealed {
        .bg{
            opacity: 0.03;
        }

        img{
            opacity: 0;
        }
    }

    .image.col0 .bg{
        background-color: $col-light;
    }
    .image.col1 .bg{
        background-color: $col-light;
        // background-color: $col-teal;
    }
    .image.col2 .bg{
        background-color: $col-light;
        // background-color: $col-cyan;
    }
    .image.col3 .bg{
        background-color: $col-light;
        // background-color: $col-yellow;
    }
    
   
}