@use '../../assets/styles/_breakpoints.scss' as *;
@use '../../assets/styles/_colors.scss' as *;
@use '../../assets/styles/_vars.scss' as *;

.gallery-container{
    // padding-bottom: 30vh;
    width: 100%;
    // background-color: purple;
    overflow:hidden;
}

.gallery-page{


    min-height: 100vh;
    position:relative;
    // display: block;
    padding-bottom: 30vh;
    
    .gallery-page-anchor{
        position:absolute;
        left:0;
        width:100%;
        height: 30vh;
    }
    .gallery-page-anchor.top{
        top:0;
        // background-color: green;
    }
    .gallery-page-anchor.bottom{
        bottom:20vh;
        // background-color: red;
    }
    .main-column{
        display: flex;
        flex-direction: column;
    }
   

    .content-container{
        display: flex;
        flex-direction: column;
        height: 100%;
        // min-height: 100vh;
        position: relative;

        .main-column-content{
            align-self: unset;

        }
        .content{
            position: relative;
            z-index:100;
            

                .images{
                    width: 60%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    min-height: 10px;
                    // margin-left: 5%;

                    

                    @include bp(s-down){
                        width: 100%;
                    }
                    
                }
            
            .text-container{
                width: 40%;
                height:100vh;
                position:fixed;
                top:0;
                right: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                
                @include bp(s-down){
                    position: relative;
                    width: 100%;
                    height: unset;
                    top:unset;
                    right:unset;
                    padding-bottom: 5rem;
                }
               
                .text{
                    
                    width:90%;
                    padding-left: 5%;
                    padding-right: 5%;
                    transition:all 0.3s;
                    
                    @include bp(s){
                        
                        width: 100%;
                        padding:0;
                        
                    }

                    .type{
                        color:$col-teal;
                    }


                    h2{
                        margin:0;
                        font-weight: 700;
                    }

                    
                    .summary{
                        font-weight: 700;
                        margin-top:0;
                    }

                    .badges{
                        display: flex;
                        flex-direction: row;
                        margin-top:1.5rem;
                        margin-bottom: 0.7rem;
                        width: 100%;
                        flex-wrap:wrap;
                        

                        .badge{
                            // width: 6rem;
                            margin-right: 2rem;

                            @include bp(m){
                                margin-right: 1rem;
                            }
                            
                            img{
                                // width: 100%;
                                height: 3.5rem;
                                max-width: 100%;
                            }
                        }
                        .badge:first-child{
                            // margin: 0;
                        }
                    }

                    .description{

                    }

                    .skills-title{
                        color:$col-teal !important;
                    }

                    .skills{
                        font-size: $font-size-body-xs;
                        text-transform: uppercase;
                    }
                }
            }
            
        }
        
    }
}

.gallery-page.in-view{

    display: block;

    .text-container{
        
        transform:translateY(0rem);
        transition-delay: 0s;

        @include bp(s-down){
            transform:unset;
            transition-delay:unset;
        }
       

        .text>*{
            transition:all 0.15s ease-out;
            transform: translateX(0);
            opacity: 1;
        }

        .text{
            // opacity: 1;

            .type{
                transition-delay: 0s;
            }


            h2{
                transition-delay: 0.02s;
            }
            .badges{
                transition-delay: 0.04s;
            }
            
            .summary{
                transition-delay: 0.06s;
            }

            .description{
                transition-delay: 0.08s;
            }

            .skills-title{
                transition-delay: 0.1s;
            }

            .skills{
                transition-delay: 0.12s;
            }
        }
    }
    
}
@mixin out-of-view {
    display: hidden;
    .text-container{
        transition-delay: 0.35s !important;
        transform:translateY(-1000rem);

        
      

        .text>*{
            transition:all 0.15s ease-in;
            transform: translateX(5rem);
            opacity: 0;
        }

        @include bp(s){
            transform:unset;
            transition-delay:unset;
            .text>*{
                opacity: 0;
            }
        }

        .text{
            // opacity: 0;
            .type{
                transition-delay: 0.12s;
            }


            h2{
                transition-delay: 0.1s !important;
            }
            .badges{
                transition-delay: 0.08s;
            }
            
            .summary{
                transition-delay: 0.06s;
            }

            .description{
                transition-delay: 0.04s;
            }

            .skills-title{
                transition-delay: 0.02s;
            }

            .skills{
                transition-delay: 0s;
            }
            
        }
    }
}
.gallery-page.out-of-view{

    @include out-of-view();
    
   
}

.screen-home .gallery-page,
.screen-skills .gallery-page,
.screen-contact .gallery-page{
    @include out-of-view;
}

