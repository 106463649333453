@use '../../assets/styles/colors' as *;
@use '../../assets/styles/breakpoints' as *;

.page1{
    min-height: 100vh;
    position:relative;
    overflow:hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    
    
    .bg{
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        background-color: $col-cyan;
        transition: all 0.8s;
        top:0;
        left:0
        // opacity: 0.5;
    }

    .shape{
        position:absolute;
        width: 12rem;
        height: 12rem;
        // transform: scale(1);
        transition: opacity 0.3s;
        // opacity:0;
        
        
        @include bp(s-down){
            width: 10rem;
            height: 10rem;
        }

        .inner-top{
            top:0;
            // transition: top 0.3s;
            position:absolute;
            width: 100%;
            height: 100%;
            
        }

        .inner-rotate{
            position:absolute;
            width: 100%;
            height: 100%;
            transform-origin: 50% 50%;
            transition: transform 20s;
        }

        img{
            position:absolute;
            width: 100%;
            height: 100%;
            transition: top 0.3s;
            filter: blur(0.02rem);
            opacity: 0.3;
        }
    }

    .shape.in-view{
        .inner-top{
            transition: top 0.3s ease-out;
            top:0
        }
    }

    .shape.out-of-view{
        // opacitys:0;
        
    }
    .shape.out-of-view.pos-top {
        .inner-top{
            transition: top 0.3s;
            top:-10rem
        }
    }

    .shape.out-of-view.pos-bottom {
        .inner-top{
            transition: top 0.3s;
            top:10rem
        }
    }
   
    
    .title-container{
        position:relative;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        // align-content: center;
        width: 100%;
        // border:10px solid red;
        // align-self:center;

        display: -ms-flexbox;
        -ms-flex-align: center;
        align-items: center;
        justify-self: center;


        
        button{
            display: flex;
            flex-direction: column;
            align-self: center;
            padding-bottom: 3rem;
            border: 0;
            outline:none;
            background-color: transparent;
            cursor:pointer;
            position: relative;
            // border:1px solid green;
            // overflow:visible;



            
            .title{
                position:relative;
                margin:0;
                color:$col-dark;
                align-self: center;
                
                opacity:1;
                transform: translateX(0);
                transition: transform ease-out 0.5s;
                
                
            }

            .dots{
                display:flex;
                flex-direction: column;
                align-content: center;
                justify-content: center;
                position: absolute;
                left: calc(50% - 0.5rem);
                bottom: 0rem;
                // border: 1px solid red;
                .dot{
                    width: 0.5rem;
                    height: 0.5rem;
                    background-color: $col-dark;
                    border-radius: 0.5rem;
                    margin-bottom: 0.8rem;
                    opacity:0;
                    transition:opacity 0.3s;
    
                   
                }

                .dot.dot2{
                    transition-delay: 0.1s;
                }
                .dot.dot3{
                    transition-delay: 0.2s;
                }
    
                
            }
    
        }

        button:hover{
            .dots{
                .dot{
                    opacity: 1;
                }
            }
        }

        
        
            
    }

    
}

.page1.in-view{
    .shape{
        opacity:1;
    }
}

.page1.out-of-view{
    .shape{
        // opacity:0;s
    }
}



.page1.out-of-view.pos-top {
    .title{
        transition: transform ease-in 0.3s, opacity 0.3s;
        transform: translateX(-10rem);
        opacity:0;
    }
}
.page1.out-of-view.pos-bottom {
    .title{
        transition: transform ease-in 0.3s, opacity 0.3s;
        transform: translateX(10rem);
        opacity:0;
    }
    
}



.device-touch{
    .page1{
        .title-container{
            button{
                .dot{
                    opacity:1
                }            
            }
        }
    }
    
}


/* IE10/11-specific styles go here */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    
    .page1 {
        display: -ms-flexbox;    

        .title-container{
            // margin-top: auto;
            // margin-bottom: auto;
            // border:10px solid red;
            padding-top: 30vh;
            padding-bottom: 30vh;
                
        }

        .shape{
            display: none;
        }
    }
  }