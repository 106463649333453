@use '../../assets/styles/_vars.scss' as *;
@use '../../assets/styles/_colors.scss' as *;

.pages{

    .pages-intersector{
        position: absolute;
        left:0;
        
        height: 1px;
        width: 100%;
        z-index:3000;
    }
    .pages-intersector.top{
        // background-color: green;
        top:0;
    }
    .pages-intersector.bottom{
        // background-color: red;
        
        // Should be the same value as ScreenAnchor bottom, so that 'screen-xxxx' and 'section-pages' 
        // css change happens at the same time
        bottom:20px;
    }

    .pages-intersector.all{
        height: 100%;
        width: 1px;
        // background-color: #ff66dd88;
    }
}
