@use '../../assets/styles/_colors.scss' as *;
@use '../../assets/styles/_breakpoints.scss' as *;
@use '../../assets/styles/_vars.scss' as *;
@use '../../assets/styles/_base.scss' as *;

.poll{
    display: flex;
    flex-direction: column;
    // width: 30rem;
    // max-width: 30rem;
    align-self: center;
    width: 100%;
    
    .question{
        display: flex;
        flex-direction: column;
        align-self: center;
        width: 100%;

        ul{
            display:flex;
            flex-direction: column;
            align-content: center;
            padding:0;
            width: 100%;
            // max-width:30rem;
            margin:0;
            margin-top:2rem;
            align-self: center;
            justify-content: center;
            max-width: 30rem;
        }
    
        li{
            list-style: none;
            margin-top:1rem;
            width: 100%;
            transition: all ease-out 0.3s;

            button{
                border-radius: 3rem;
                width:100%;
                padding:1.5rem 2.5rem;
                border:0;
                cursor: pointer;
                font-size: $font-size-body-xl;
                background-color: $col-light-ghost;
                transition:all 0.3s;
                text-align: left;
                color:$col-dark;
                box-shadow: 0 0.5rem 1rem $col-dark-vapour;
                -webkit-tap-highlight-color: transparent;
    
                
    
                @include bp(s){
                    padding:1.5rem 2rem;
                    font-size: $font-size-body-l;
                }
            }
    
            
            
            button.light{
                color:$col-light;
                background-color: $col-light-vapour;
            }
        
            button:hover{
                outline: none; 
                background-color:$col-light;
                // transform:rotate3d(0, 0, 0, 0deg);
                box-shadow: 0 0.5rem 3rem $col-cyan;
                transform: rotate3d(0, 1, 0, 90deg);
            }
    
            button.light:hover{
                color:$col-dark
            }

            button:focus{
                background-color:$col-light;
                color:$col-dark;
            }
        }
        li:first-child{
            margin-top:0;
        }

        li.in-view button{
            opacity: 1;
            transform: rotate3d(0, 0, 0, 0deg);
            transition-delay: 0.2;
            
        }
        li.out-of-view button{
            opacity: 0;
            transform: rotate3d(0, 20, 0, 90deg);
            // transition-delay: 0;
        }
        
        


    }

    .question.with-results-spacer{
        margin-bottom:10rem;
    }

    .results.revealed{
        display: flex;
    }
    .results{
        
        display: flex;
        // display: none;
        flex-direction: column;
        align-self: center;
        position: relative;
        width:100%;

        .anchor{
            position: absolute;
            top:-8rem;
            width: 1px;
            height: 1px;
            // background-color: red;
        }


    
        table{
            width: 100%;
            // max-width: 30rem;
            border:0;
            padding:0;
            margin-top:2rem;

            // tr:hover .bar-and-value-container .bar.revealed{
            //     // opacity: 0.1 !important;
            //     width: 100% !important;
            //     transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) !important;
            // }

            tr{
                
                
                
                
                .label-cell{
                    width: 30%;
                    padding-right: 5%;
                    padding-top:1rem;

                    @include bp(s-down){
                        width: 40%;
                    }
                    .label{
                        
                        font-size: $font-size-body-xl;
                        text-align: left;
                        align-self: center;
                        transition:all 1s;
                        opacity:0;

                        @include bp(s-down){
                            font-size: $font-size-body-l;
                        }
                    }
                    .label.small{
                        font-size: $font-size-body;
                    }

                    .label.dark{
                        color: $col-dark;
                    }
                    .label.light{
                        color: $col-eggshell;
                    }

                    .label.revealed{
                        opacity:1;
                    }
                }

                .label-cell.group{
                    width: 90%;
                    padding-right: unset;
                    padding-top:2.5rem;

                    .label{
                        @include smallcaps;
                        color:$col-dark-ghost;
                        text-align: center;
                    }
                   
                    @include bp(s-down){
                        width: 90%;
                    }

                }
                .bar-cell{
                    width: 70%;
                    padding-top:1rem;
                    // background-color: green;

                    @include bp(s-down){
                        width: 60%;
                    }

                    .bar-and-value-container{

                        display: flex;
                        flex-direction: row;
                        
                
                              
                        .bar{    
                            background-color: $col-dark-wraith;
                            width:0%;
                            height: 3rem;
                            border-radius: 2rem;
                            transition:all 1s;
                            opacity:0;

                            @include bp(s-down){
                                height: 2rem;
                                border-radius: 1rem;
                            }
                        }
                        
                        .bar.small{
                            height: 0.5rem;
                            border-radius: 0.25rem;
                        }
                        .bar.revealed{
                            opacity:1;
                        }

                        .value{
                            color: $col-dark-ghost;
                            font-size: $font-size-body-small;
                            align-self: center;
                            margin-left: 1rem;
                            width:10%;
                            @include bp(s-down){
                                font-size: $font-size-body-xs;
                            }
                        }
                        .value.dark{
                            color: $col-dark;
                        }
                        .value.light{
                            color: $col-eggshell-ghost;
                        }
                    }
                }

                
                .unit-cell{
                    width: 10%;
                    padding-top:1rem;
                    .value{
                        color: $col-dark-ghost;
                        font-size: $font-size-body-small;
                        align-self: center;
                        margin-left: 1rem;
                        width:10%;
                    }
                    
                }
                
                

            
            }
        }

        

        li:first-child{
            margin-top:0;
        }


    }

    

    .footnote{
        text-align: center;
        margin-top:5rem;
    }

    
    
}

.device-touch .poll {
    .question{
        button:focus{
            background-color:$col-light;
            color:$col-dark;
        }

        
    }
}