@use '../../assets/styles/breakpoints' as *;

.home-bg-icon{
    position: absolute;
    width: 150px;
    height: 150px;
    transition: all 1s;
    opacity:0;
    
    @include bp(xs){
        width: 75px;
        height: 75px;
    }

    @include bp(s){
        width: 100px;
        height: 100px;
    }

    img{
        opacity:1;
        position: absolute;
        width: 100%;
        height: 100%;
        transition:all 1s;
        top:0;
        // border:2px solid green;
    }
}



.home-bg-icon.revealed{
    opacity:1
}

.home-bg.out-of-view.pos-top {
    .home-bg-icon{
        img{
            top:-5rem;
            opacity:0;
        }
    }
}

.home-bg.out-of-view.pos-top {
    .home-bg-icon{

        img{
            top:-10rem;
            opacity:1;
        }
    }
}