.screen-anchor{
    position:absolute;
    top: 0px;
    width: 100vw;
    height: 1px;
    // background-color: green;
    z-index: 150;
}

.screen-anchor.bottom{
    top:unset;
    bottom: 20px;
    // background-color: red;
    
    
}
