@use '_colors.scss' as *;
@use '_breakpoints.scss' as *;
@use '_vars.scss' as *;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,900&display=swap');

// *{scroll-behavior: smooth;}


html{
    
    
    width:100%;
    min-height:100%;
    display:flex;
    margin:0;
}

body {
    margin: 0;
    width:100%;
    min-height:100%;
    display:flex;
    background-color:$col-bg;
    text-align: left;
    color:$col-dark;
}
  
#root{
    min-height:100%;
    width:100%;
    display:flex;
}


/* ------------------------------------------------------------------------------------
* TYPOGRAPHY 
*/

html{
    font-family: $font-body;
    font-size: 16px;
    font-weight:100;
    
    

    
    @include bp(m) {
        font-size:14px;
    }
    
    @include bp(s) {
        font-size:14px;
    }
    @include bp(xs) {
        font-size:10px;
    }
  
}

h1{
    font-family:$font-header;
    color:$col-dark;
    font-size:4.5rem;
    line-height:1.2em;
    letter-spacing: -0.01em;
    font-weight:900;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    @include bp(s-down){
        font-size:3.5rem;
    }
}

h1.small{
    font-weight:400;
    font-size:1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.08em;
    line-height: 1.3em;
    margin-top: 0;
}
h1.small b{
    font-weight:600;
}
h2{
    font-family:$font-header;
    color:$col-dark;
    letter-spacing: -0.01em;
    font-weight:500;
    font-size:2.5rem;
    line-height:1.15em;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    
}

h3, h4{
    /* font-family:'BlackerTextMedium';  */
    font-family:$font-header;   
    color:$col-dark;
    letter-spacing: -0.01em;
    font-weight:500;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}



h3{
    font-size:1.6rem;
    line-height:1.3em;
}



h4{
    font-size:1.6rem;
    line-height:1.28em;
}

h5{
    font-family:$font-header;
    text-transform: uppercase;
    font-size: $font-size-body;
    letter-spacing:0.1em;
    color:$col-dark;
}

p {
    line-height:1.45em;
    font-family:$font-body;
}
p a{
    text-decoration: underline;
    // transition: all 0.3s;
    // padding:0.1rem;
}
p a:hover{
    // opacity:0.5;
    background-color: $col-yellow;
    // padding:0.2rem;
}
p, li, a{
    font-size:$font-size-body;
}
p strong, p b{
    font-weight: 600;
}

p.small, li.small, a.small{
    font-size:$font-size-body-small;
    
}

p.footnote, p.footnote a, li.footnote, li.footnote a, a.footnote{
    font-size:$font-size-body-small;
    color:$col-dark-ghost;
    transition: all 0.2s;
    word-break: break-all;
}
p.footnote a:hover, li.footnote a:hover, a.footnote:hover{
    background-color: transparent;
    color:$col-dark;
    background-color: $col-yellow;
}

// Long line length text has greater leading
.text-column p{
    line-height:1.7em;
}


quote{
    display: inline-block;
    padding-left: 3rem;
    border-left:0.2rem solid $col-header-faint;
    font-family: $font-italic;
    font-size: $font-size-body-l;
}

button{
    font-family:$font-button;
}

input[type=text]{
    font-family:$font-button;
    letter-spacing:-0.04em;
    // font-weight:600;
}

input[type=text]::placeholder{
    color:$col-dark-ghost;
}

i{
    font-family:$font-italic;
}
h3 i{ 
    font-family:$font-header-italic;
}

p i{
    font-family:$font-body-italic;
}



.text-column p {
    color: $col-dark;
}


a{
    color:$col-link;
    text-decoration:none;
}
a:hover{
    
    text-decoration:underline;
}

@mixin smallcaps {
    font-size:$font-size-body-smallcaps;
    letter-spacing:0.03em;
    text-transform: uppercase;
    font-family: $font-body;
    font-weight:normal;
    
}
.smallcaps{
    @include smallcaps;
}

.xsmallcaps{
    font-size:$font-size-body-xsmallcaps;
    letter-spacing:0.03em;
    text-transform: uppercase;
    font-family: $font-body;
    font-weight:normal;
    
}

.bold{
    font-weight: bold;
}

.faint{opacity:0.5}
.ghost{opacity:0.2}
.vapour{opacity:0.1}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}





.pages{
    position: relative;

    .pages-intersector{
        position: absolute;
        left:0;
        // background-color: red;
        height: 1px;
        width: 1px;
        z-index:3000;
    }
    .pages-intersector.top{
        top:0;
    }
    .pages-intersector.bottom{
        bottom:0;
    }
}


.pages-title-container{
    // position: relative;
    // top:0rem;
    // left:0rem;
    // background-color: green;
}

.pages-title{
    position: fixed;
    top:0rem;
    left:0rem;
    z-index:2000;
    width:100%;
    .slab{
        width: 100%;
        background-color: $col-cyan;
        height:0.5rem;
        opacity: 0;
        transition:all 0.3s;
    }
    .text{
        display:none;
        margin-top:2rem;
        font-size: $font-size-body-xl;
        font-weight: 700;
        color:$col-dark;
        transform:translateX(-10rem);
        transition:all 0.3s;
        opacity: 0;

        @include bp(s){
            margin-top:3rem;
        }

        @include bp(xs){
            margin-top:1.1rem;
        }
    }
}

.pages-title.revealed{
    .slab{
        opacity:1;
    }
    .text{
        transform:translateX(3rem);
        opacity: 1;

        @include bp(s-down){
            transform:translateX(1.5rem);
        }
    }
}

@mixin text-selection{
    background-color: $col-yellow;
    color: $col-dark;
}

/*** Works on common browsers ***/
::selection {
    @include text-selection;
}

/*** Mozilla based browsers ***/
::-moz-selection {
    @include text-selection;
}

/***For Other Browsers ***/
::-o-selection {
    @include text-selection;
}

::-ms-selection {
    @include text-selection;
}

/*** For Webkit ***/
::-webkit-selection {
    @include text-selection;
}