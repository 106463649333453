@use '../../assets/styles/_breakpoints.scss' as *;
@use '../../assets/styles/_vars.scss' as *;
@use '../../assets/styles/_colors.scss' as *;


.nav-container{
    width:100%;
    position:fixed;
    top:0;
    right:0;
    z-index:200;

    // .nav-bg{
    //     display: block;
    //     position: absolute;
    //     width: 100%;
    //     height:4rem;
    //     z-index:0;
    //     .nav-col{
    //         position:absolute;
    //         top:0;
    //         width: 100%;
    //         height: 100%;
    //         opacity: 0;
    //         transition:opacity 0.3s;
    //     }

    //     .nav-col-bg-grey{
    //         background-image: linear-gradient($col-bg-grey, transparent);
    //     }
    //     .nav-col-cyan{
    //         background-image: linear-gradient($col-cyan,  transparent);
    //     }
    //     .nav-col-dark{
    //         background-image: linear-gradient($col-dark, transparent);
    //     }
    //     .nav-col-light{
    //         background-image: linear-gradient($col-light, transparent);
    //     }
    //     .nav-col-pink{
    //         background-image: linear-gradient($col-pink-plaster, transparent);
    //     }
    //     .nav-col-teal{
    //         background-image: linear-gradient($col-teal, transparent);
    //     }
    //     .nav-col-yellow{
    //         background-image: linear-gradient($col-yellow, transparent);
    //     }
        
        
    // }
}
#nav{
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position:absolute;
    right:0;
    top:0;
    
    
   
    #hamburger{
        display: none;
    }

    ul{
        display: flex;
        flex-direction: row;
        padding:0;
        margin:0;
        z-index:1
    }
    li{
        list-style: none;
        display:flex;
    }
    li a{
        padding:2rem 2rem 1rem 2rem;
        text-decoration: none;
        color:$col-text;
        text-transform: uppercase;
        font-size: $font-size-body-xs;
        font-weight: 500;
        letter-spacing: 0.1em;
        transition:all 0.5s;
        -webkit-tap-highlight-color: transparent;

        @include bp(xs){
            padding:0rem;
        }

        @include bp(s-down){
            padding:1rem;
        }
    }
    li a:hover{
        text-decoration: none;
        outline: none;
        // border: 2px solid red;
        
    }
    li a:focus{
        // border: 1px solid red;
        // outline:
    }

    .hamburger{
        display:none;
    }
    
    #navbar{
        width: 2rem;
        height:3px;
        background-color: $col-dark;
        min-width: 2rem;
        position: absolute;
        left:0;
        bottom:0;
        transition: all 0.3s;
        // transition: unset;
    }

    #navbar.no-anim{
    
        transition: unset;
    }
    

   
    

}


// .screen-home{.nav-container .nav-bg{.nav-col{opacity:0};.nav-col-pink{opacity:1}}}

// .screen-skills{.nav-container .nav-bg{.nav-col{opacity:0};.nav-col-cyan{opacity:1}}}
// .screen-skills.section-pages{.nav-container .nav-bg{.nav-col{opacity:0};.nav-col-bg-grey{opacity:1}}}

// .screen-experience{.nav-container .nav-bg{.nav-col{opacity:0};.nav-col-teal{opacity:1}}}
// .screen-experience.section-pages{.nav-container .nav-bg{.nav-col{opacity:0};.nav-col-dark{opacity:1}}}

// .screen-contact{.nav-container .nav-bg{.nav-col{opacity:0};.nav-col-yellow{opacity:1}}}
// .screen-contact.section-pages{.nav-container .nav-bg{.nav-col{opacity:0};.nav-col-light{opacity:1}}}

// NAV IN EXTRA SMALL STATE
@include bp(xs){
    #nav{

        width: 100%;
        ul{
            position:absolute;
            top:-200px;
            left:0;
            
            background-color: $col-text;
            // transform:translateY(-200px);
            transition:transform 0.3s, opacity 0.3s, top 0.2s;
            opacity: 0;
            display: flex;
            flex-direction: column;
            width: 100%;
            padding:1rem 1rem;
            margin:0;
            
            

            li a{
                padding: 1rem 0 1rem 0;
                color:$col-light;
                transition:all 0.2s;
                cursor:default;
                width:calc(100% - 2rem);
            }

            li a span{
                padding-bottom:0.3rem;
                border-bottom: 2px solid transparent;
                transition: border 0.4s;
                font-weight: 600;
            }
            

        }
        #hamburger{
            display: block;
            z-index:1;
        }

        #navbar{
            display:none !important;;
        }
        
    }
    #nav.nav-open{
        

        ul{
            opacity:0.98;
            top:0;
            
            li a{
                cursor:pointer;
                color:$col-light;
                // display:flex;
                // flex-direction:column;
            }

            li a:hover{
                transition:all 0.3s;
                margin-left:0;
            }

           
            
            li a:hover{
                // padding-bottom: 0.5rem;
                margin-left:0.5rem;
                
                
            }

            li a:hover span{
                border-bottom: 2px solid $col-cyan;
                
            }
            li a.item-home span{
                border-color:$col-pink-plaster;
            }
            li a.item-skills span{
                border-color:$col-cyan;
            }
            li a.item-experience span{
                border-color:$col-teal;
            }
            li a.item-contact span{
                border-color:$col-yellow;
            }
        }

        

       
    }

    
}



.screen-experience.section-experience-pages #nav{
    li a{
        color:$col-light;
    }

    #navbar{
        background-color: $col-light;
    }

    #hamburger{
        .bun-line{
            background:$col-eggshell; 
        }
    }

}

.screen-error #nav{

    #navbar{
        opacity:0
    }
}   

.screen-error #nav:hover{

    #navbar{
        opacity:1
    }
}   

