@use 'colors' as *;
@use 'vars' as *;
@use 'breakpoints' as *;



.main-column{
    margin:0 auto;
    position:relative;
    width:1280px;
    max-width:1280px;
    // min-width:320px;

    @include bp(xs){
        max-width:360px;
        width:100%;
    }
    @include bp(s){
        max-width:640px;
        width:100%;
    }

    @include bp(m){
        max-width:1024px;
        width:100%;
    }

    @include bp(l){
        max-width:1280px;
        width:100%;
    }
    
}
.main-column--narrow{
    max-width:1024px;

    @include bp(xs){
        max-width:360;
        width:100%;
    }


    @include bp(s){
        max-width:640px;
        width:100%;
    }

    @include bp(m){
        max-width:1024px;
        width:100%;
    }

    @include bp(l){
        max-width:1024px;
        width:100%;
    }
}

.main-column-content{
   

    @include bp(s-down){
        padding-left:20px;
        padding-right:20px;

    }

    @include bp(m){
        padding-left:40px;
        padding-right:40px;
    }

    @include bp(l){
        padding-left:40px;
        padding-right:40px;
    }

    @include bp(xl){
        padding-left:40px;
        padding-right:40px;
    }
}



.text-column{
    max-width:$max-readable-width;
}
