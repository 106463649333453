@use '../../assets/styles/breakpoints' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/colors' as *;

#experience{
    // min-height: 100vh;
    background-color: $col-bg-dark;
    width: 100%;
    // overflow: hidden;
    position:relative;

   
    
    >.content{   
        transition:all 1s;
        opacity:1;
        z-index: 100;
        position:relative;

        .pages-title{
            .slab{
                background-color: $col-teal;
            }
            .text{
                color:$col-eggshell;
            }
        }
    }


  
    // .content.pos-top.out-of-view h1{
    //     transform: translateX(-200px);
    // }

    // .content.pos-bottom.out-of-view h1{
    //     transform: translateX(200px);
    // }

   

    // -----------------------------------------------------------------------------
    // PAGE 1

    .page1{

        

        // min-height: 100vh;
        .bg{ 
            background-color: $col-teal;
        }
        
        .title{
            color:$col-dark;
        }
    }


    .pages{
            
    
         
        h1,h2,h3,h4,h5,p{
            color:$col-eggshell;
        }

        h1,h2,h3,h4,h5,p{
            color:$col-eggshell;
        }


        .poll .footnote,
        .poll .footnote a{
            color:$col-light-faint;
        }
        .poll .footnote a:hover{
            color:$col-dark;
            background-color: $col-yellow;
        }
        
        
        .page{
            width: 100%;
        }

        

        // -----------------------------------------------------------------------------
        // PAGE 2


        .page2{
            min-height: 100vh;
            position:relative;
            // background-color: $col-bg-grey;
            
        
            
            .bg-image-container {
                // position: absolute;
                width: 100%;
            
                .bg-image{
                    position: absolute;
                    top:0;
                    left:0;
                    width: 100%;

                    img{
                        width: 100%;
                        max-width: 1280px;
                    }
                }
            }

            .content-container{
                display: flex;
                flex-direction: column;
                height: 100%;
                min-height: 100vh;
                position: relative;

                .main-column-content{
                    align-self: center;
                }

                .content{   
                    display:flex;
                    flex-direction: column;
                    align-content: center;
                    transition:all 1s;
                    padding-top: 15vh;
                    // padding-bottom: 30vh;
                }


                h1{
                    text-align: center;
                }

                .poll{
                    margin-top:3rem;
                }
            }

            

            .items{   
                padding: 0;
                margin:0;
            }
        
            .item{   
                list-style: none;
                width: 40rem;
                background-color: $col-light;
                border-radius: 1rem;
                padding: 2rem;
                margin-bottom: 1rem;
                opacity: 0;
                transition: all 0.3s;
                transform: translateX(100px);
                max-width: 20rem;
            }
            .item.in-view{   
                opacity: 1;
                transform: translateX(0);
            }
        }
    }

    // -----------------------------------------------------------------------------
    // PAGE 3



    .page3{
        // min-height: 100vh;
        position:relative;
        display: block;
        

        .bg-image-container {
            // position: absolute;
            width: 100%;
        
            .bg-image{
                position: absolute;
                top:0;
                left:0;
                width: 100%;

                img{
                    width: 100%;
                    max-width: 700px;
                }
            }
        }

        .main-column{
            display: flex;
            flex-direction: column;
            // border:2px solid red;
        }
       

        .content-container{
            display: flex;
            
            flex-direction: column;
            height: 100%;
            // min-height: 100vh;
            position: relative;


            .main-column-content{
                align-self: center;
            }

            .content{   
                display:flex;
                flex-direction: column;
                align-content: center;
                padding-top: 10vh;
                padding-bottom: 30vh;
                
                // We need to calculate the element width for IE, otherwise the child elements do not wrap
                width: calc(100% - 80px);
                @include bp(s-down){
                    width: calc(100% - 40px);
                }

                .panels{
                    display:flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    flex-wrap: wrap;
                    margin-top:6rem;
                    // border:1px solid red;
                    
                    .panel{
                        width: 30%;
                        margin-bottom: 3rem;
                        transition: all 0.3s;
                        opacity:0;
                        transform:translateY(2rem);

                        

                        @include bp(xs){
                            width:100%;
                            // margin-right:5%;
                        }
                        @include bp(s){
                            width:45%;
                            // margin-right:5%;
                        }

                        .pic{
                            width:100%;
                            overflow:hidden;
                            img{
                                width: 100%;
                                transform: scale(1);
                                transition: all 1s;
                                transform-origin: 50% 50%;
                            }
                            img:hover{
                                transform: scale(1.03);
                            }
                        }
                    }
                    .panel1{transition-delay: 0.2s;}
                    .panel2{transition-delay: 0.3s;}
                    .panel3{transition-delay: 0.4s;}
                    
                }

                .panels.in-view{
                    .panel{
                        opacity:1;
                        transform:translateY(0);
                    }
                }
            
                .panels.out-of-view{
                    .panel{
                        opacity:0;
                        transform:translateY(2rem);
                        
                    }
                    .panel3{transition-delay: 0s;}
                    .panel2{transition-delay: 0.1s;}
                    .panel1{transition-delay: 0.2s;}
                }
                .panels.pos-top.out-of-view{
                    .panel{
                        transform:translateY(-2rem)
                    }
                }
                
                
            }
        }
    }



    .projects{
        width: 100%;
        max-width: 100%;
        // opacity:0.3;
        display:block;
        // overflow:hidden;

        .projects-title{
            text-align: center;
            margin-bottom: 6rem;
        }
    }

    

   
    

   
    
}


// .screen-experience.section-pages #experience .page3 .bg-image-container{
//     position:fixed;
//     top:0;
//     left:0;
// }