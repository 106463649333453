@use '../../assets/styles/breakpoints' as *;
@use '../../assets/styles/colors' as *;


$animation: 0.3s;	// animation speed


#nav{

    .hamburger {
        transform: scale(1);
        display: block;
        width: 68px;
        height: 68px;
        -webkit-touch-callout: none;
        user-select: none;
        // z-index:1;


        cursor:pointer;
        position:absolute;
        top:0;
        right: 0;
        *, *:before, *:after {
            box-sizing: border-box;
        }
    
        *, *:before, *:after {
            box-sizing: border-box;
        }

        @include bp(xs){
            transform: scale(0.6);
        }

        .burger-icon {
            position: absolute;
            padding: 20px 16px;
            height: 68px;
            width: 68px;
        }
    
        .burger-container {
            position: relative;
            height: 28px;
            width: 36px;
        }
    
        .bun-line{
            position: absolute;
            display: block;
            height: 4px;
            width: 36px;
            border-radius: 2px;
            background: $col-dark;
        }

        .burger-bun-top {
            top: 0;
            transform-origin: 34px 2px;
        }
    
        .burger-bun-bot {
            bottom: 0;
            transform-origin: 34px 2px;
        }
        //.burger-filling {
        //  @include transition(all,($animation/2.5),ease-in-//out);
        //}
        // relative parent is the button
        .burger-filling {
            top: 12px;
        }
    
    
    }


   
    .hamburger.is-open{
        .bun-line{
            background:$col-eggshell;
        }
    }


   



    // bun animations 
    .hamburger.is-open {
        .burger-bun-top {
            animation: bun-top-out $animation linear normal;
            animation-fill-mode:forwards;
        }
        .burger-bun-bot {
            animation: bun-bot-out $animation linear normal;
            animation-fill-mode:forwards;
        }
    }
    .hamburger.is-closed {
        .burger-bun-top {
            animation: bun-top-in $animation linear normal;
            animation-fill-mode:forwards;
        }
        .burger-bun-bot {
            animation: bun-bot-in $animation linear normal;
            animation-fill-mode:forwards;
        }
    }

    @keyframes bun-top-out {
        0% {
            left: 0;
            top: 0;
            transform: rotate(0deg);
        }
        20% {
            left: 0;
            top: 0;
            transform: rotate(15deg);
        }
        80% {
            left: -5px;
            top: 0;
            transform: rotate(-60deg);
        }
        100% {
            left: -5px;
            top: 1px;
            transform: rotate(-45deg);
        }
    }

    @keyframes bun-bot-out {
        0% {
            left: 0;
            transform: rotate(0deg);
        }
        20% {
            left: 0;
            transform: rotate(-15deg);
        }
        80% {
            left: -5px;
            transform: rotate(60deg);
        }
        100% {
            left: -5px;
            transform: rotate(45deg);
        }
    }


    @keyframes bun-top-in {
        0% {
            left: -5px;
            bot: 0;
            transform: rotate(-45deg);
        }
        20% {
            left: -5px;
            bot: 0;
            transform: rotate(-60deg);
        }
        80% {
            left: 0;
            bot: 0;
            transform: rotate(15deg);
        }
        100% {
            left: 0;
            bot: 1px;
            transform: rotate(0deg);
        }
    }

    @keyframes bun-bot-in {
        0% {
            left: -5px;
            transform: rotate(45deg);
        }
        20% {
            left: -5px;
            bot: 0;
            transform: rotate(60deg);
        }
        80% {
            left: 0;
            bot: 0;
            transform: rotate(-15deg);
        }
        100% {
            left: 0;
            transform: rotate(0deg);
        }
    }


    // burger filling
    .hamburger.is-open {
        .burger-filling {
            animation: burger-fill-out $animation linear normal;
            animation-fill-mode:forwards;
        }
    }

    .hamburger.is-closed {
        .burger-filling {
            animation: burger-fill-in $animation linear normal;
            animation-fill-mode:forwards;
        }
    }

    @keyframes burger-fill-in {
        0% {
            width: 0;
            left: 36px;
        }
        40% {
            width: 0;
            left: 40px;
        }
        80% {
            width: 36px;
            left: -6px;
        }
        100% {
            width: 36px;
            left: 0px;
        }
    }

    @keyframes burger-fill-out {
        0% {
            width: 36px;
            left: 0px;
        }
        20% {
            width: 42px;
            left: -6px;
        }

        40% {
            width: 0;
            left: 40px;
        }

        100% {
            width: 0;
            left: 36px;
        }
    }

}