@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/breakpoints' as *;
 
 /* The Modal (background) */
 .modal {
    display: block;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: transparent;
    
    
    .modal-scrim {
        position:fixed;
        width:100%;
        height: 100%;
        background-color: #000;
        background-color: #00000033;
        transition:opacity 0.3s;
        opacity:0;

    }

    /* Modal Content/Box */
    .modal-content {
        background-color: #fefefe;
        margin: 10vh auto;
        padding: 0;
        width: 80%;
        border-radius:0.3rem;
        position: relative;
        transition:opacity 0.3s, transform 0.3s cubic-bezier(0.175, 0.885, 0.320, 1.275);;
        opacity:0;
        box-shadow:0 2rem 5rem #00000022;

        transform: translateY(2rem);


        /* The Close Button */
        .close-but{
            border:0;
            outline:0;
            background-color: $col-dark-imagined;
            border-radius:10rem;
            cursor:pointer;
            position: absolute;
            right: 1rem;
            top:1rem;
            display:flex;
            flex-direction:column;
            align-content: center;
            justify-content: center;
            width:4rem;
            height:4rem;

            img {
                width:1.5rem;
                height:1.5rem;
                align-self: center;
                // position: absolute;
                // top:0.2rem;
                // left:0.1rem;

                // color: $col-dark;
                // font-size: 3rem;
                // font-weight: 300;
                // line-height:3.8rem;
                // padding:0 1rem;
            }
        }
        
        
        .close-but:hover,
        .close-but:focus {
            // color: black;
            
            background-color: $col-yellow;
            cursor: pointer;

            .icon{
                text-decoration: none;
            }
        } 
        
    }
  }

  
 .modal.revealed{
    .modal-scrim{
        opacity:1;
    }
    .modal-content{
        opacity:1;
        transform: translateY(0rem);
    }
  } 
