@use '../../assets/styles/breakpoints' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/colors' as *;


#skills{
    background-color: $col-grey-light;
    // margin:3rem;
    // height: 100vh;
    width: 100%;
    // overflow: hidden;
    position:relative;

    #skills-bg{
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        
    }
    
    >.content{   
        // padding:3rem;
        transition:all 0.2s;
        opacity:1;
        z-index: 100;
        position:relative;
    }


  
    // .content.pos-top.out-of-view h1{
    //     transform: translateX(-200px);
    // }

    // .content.pos-bottom.out-of-view h1{
    //     transform: translateX(200px);
    // }

    
    
    .page{
        width: 100%;
        
    }

    // -----------------------------------------------------------------------------
    // PAGE 1

    .page1{
       

        // min-height: 100vh !important;
        .bg{
            background-color: $col-cyan;
        }
        
        .title{
            color:$col-dark;
        }
    }

    

    // -----------------------------------------------------------------------------
    // PAGE 2


    .page2{
        min-height: 100vh;
        position:relative;
        background-color: $col-bg-grey;
        
    // background-image:-webkit-linear-gradient($col-pink-plaster, transparent);
    // background-image:-moz-linear-gradient($col-pink-plaster, transparent);
    
        
        .bg-image-container {
            // position: absolute;
            width: 100%;
        
            .bg-image{
                position: absolute;
                top:0;
                left:0;
                width: 100%;

                img{
                    width: 100%;
                }
            }
        }

        .content-container{
            display: flex;
            flex-direction: column;
            height: 100%;
            min-height: 100vh;
            position: relative;
            // background-image: linear-gradient(transparent, $col-dark-vapour);
            .main-column-content{
                align-self: center;
            }

            .content{   
                display:flex;
                flex-direction: column;
                align-content: center;
                transition:all 1s;
                padding-top: 15vh;
                // padding-bottom: 30vh;
            }


            h1{
                text-align: center;
            }

            .poll{
                margin-top:3rem;
            }
        }

        

        .items{   
            padding: 0;
            margin:0;
        }
    
        .item{   
            list-style: none;
            width: 40rem;
            background-color: $col-light;
            border-radius: 1rem;
            padding: 2rem;
            margin-bottom: 1rem;
            opacity: 0;
            transition: all 0.3s;
            transform: translateX(100px);
            max-width: 20rem;
        }
        .item.in-view{   
            opacity: 1;
            transform: translateX(0);
        }
    }


    // -----------------------------------------------------------------------------
    // PAGE 3



    .page3{
        // min-height: 100vh;
        position:relative;
        background-color: $col-bg-grey;
        display: none;

        .main-column{
            display: flex;
            flex-direction: column;
        }
       
        .bg-image-container {
            // position: absolute;
            width: 100%;
            display:flex;
            flex-direction: column;
            align-content: center;
            .bg-image{
                align-self: center;
                position: relative;
                // top:0;
                // left:0;
                width: 100%;
                max-width: 60rem;
                img{
                    width: 100%;
                    opacity: 1;
                    // max-width: 1000px;
                }
            }
        }

        .content-container{
            display: flex;
            flex-direction: column;
            height: 100%;
            // min-height: 100vh;
            position: relative;

            .main-column-content{
                align-self: center;
            }

            .content{   
                display:flex;
                flex-direction: column;
                align-content: center;
                transition:all 1s;
                padding-top: 0;
                padding-bottom: 30vh;
                max-width: 80rem;
                width:75%;

                @include bp(s-down){
                    
                    // Fill width, minus the usual content side padding at this screen size
                    width:calc(100% - calc(2 * 20px));
                }

                h1{
                    text-align:center;
                    margin-top: 6rem;
                    margin-bottom: 1rem;
                }

                .group{
                    // margin-top:2rem;
                    margin-bottom: 6rem;

                    header{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-content: center;
                        .pic{
                            width:8rem;
                            height: 8rem;
                            align-self: center;
                            img{
                                width:100%;
                                height: 100%;
                            }
                        }

                        h1{
                            margin-top:1rem;
                        }
                    }
                }
                
            }
        }
    }
    .page3.revealed{
        display: block;
    }
    

    .content.pos-top .page2 .item{
        transform: translateX(-100px);
    }

    

    // .content .stats{ 
    //     display: flex;
    //     flex-direction: column;
    // }

    // .content .statbar{ 
    //     display: inline-block;
    //     background-color: $col-light;
    //     width:0%;
    //     height: 0.5rem;
    //     border-radius: 0.25rem;
    //     transition: width 0.5s;
    //     position: absolute;
    //     top:0;
    //     left: 0;
    
    // }


    
}


