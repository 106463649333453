$col-text:#202020;

$col-pink-blossom:#FFB0DA;
$col-pink-plaster:#F1ACBF;


$col-grey-light:#F6F6F6;

$col-cyan:#6ADEE0;

$col-teal:#4BC1A2;

$col-bg:#fafaf7;
$col-bg-dark:#292929;
$col-bg-grey:#F2F2F1;

$col-dark: #303030;
$col-dark-deep: #222;
$col-dark-faint: #11111188;
$col-dark-spirit: #11111155;
$col-dark-ghost: #11111144;
$col-dark-wraith: #11111122;
$col-dark-vapour: #11111111;
$col-dark-imagined: #11111105;

$col-yellow:#FFF25B;
$col-yellow-faint:#FFF25B88;
$col-yellow-ghost:#FFF25B44;

$col-eggshell: #E2EADD;
$col-eggshell-ghost: #E2EADD44;

$col-link:#303030;

$col-light: #fff;



// ---------------------------------------

$col-header:#4b366e;
$col-header-translucent:#4b366edd;
$col-header-faint:#4b366e88;
$col-header-vibrant:#4E3874;

$col-highlight:#708938;
$col-highlight-translucent:#708938cc;
$col-highlight-light:#708938aa;
$col-highlight-faint:#70893888;
$col-highlight-ghost:#70893844;
$col-highlight-wraith:#70893822;
$col-highlight-vapour:#70893811;
$col-highlight-none:#70893801;
$col-green:#60964A;
$col-green-hover:#5b9145;
$col-green-ghost:#60964A44;
$col-green-vapour:#60964A11;
$col-green-imagined:#60964A08;


$col-light-translucent: #ffffffee;
$col-light-faint: #ffffff88;
$col-light-ghost: #ffffff44;
$col-light-wraith: #ffffff22;
$col-light-vapour: #ffffff11;
$col-light-off: #F5F4F3;
$col-green-light:#F6F7F1;
$col-but-type1-bg:#eff0e9;
$col-but-type1-bg-hover:#eaece2;
$col-blue:#346DA3;
$col-blue-hover:#30699f;
$col-pink:#B43D92;
$col-pink-hover:#B0398e;

$col-yellow-hover:#c7b133;

$col-gold:#F6AE2C;


$col-error:#aa3333;
$col-warning:#cc8833;
