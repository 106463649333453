@use '../../assets/styles/breakpoints' as *;
@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;

.preloader{
    position:fixed;
    width: 100vw;
    height: 100vh;
    background-color: $col-pink-plaster;
    // background-color: $col-yellow;
    opacity:1;
    z-index:1000;
    transition:opacity 1s;

    // Delay before revealing.
    // This is needed due to unpredictable scrollIntoView behaviour on App.componentDidMount, which forces us to set App.state.isReady just before routing to first screen.
    // (See App.init())
    transition-delay: 0.5;
}
.preloader.unrevealed{
    opacity:0;
}