@use '../../assets/styles/_breakpoints.scss' as *;
@use '../../assets/styles/_vars.scss' as *;
@use '../../assets/styles/_colors.scss' as *;

#contact{
    
    background-color: $col-light;
    width: 100%;
    // overflow: hidden;
    position:relative;
    min-height: 200vh;
    
    
   

    .content{   
        // padding:3rem;
        transition:all 0.2s;
        opacity:1;
        z-index: 100;
        position:relative;

        .pages-title{
            .slab{
                background-color: $col-yellow;
            }
            .text{
                color:$col-dark;
            }
        }
    }

    // -----------------------------------------------------------------------------
    // PAGE 1

    .page1{
        .bg{ 
            background-color: $col-yellow;
        }
        
        .title{
            color:$col-dark;
        }

        .title-container>button{
            padding-bottom:6rem;
        }
    }

    // -----------------------------------------------------------------------------
    // PAGE 2


    .page2{
        // min-height: 150vh;
        position:relative;
        // background-color: $col-bg-grey;
        padding-bottom: 30vh;
    
      

        .content-container{
            display: flex;
            flex-direction: column;
            height: 100%;
            min-height: 100vh;
            position: relative;

   
            .line-container{
                width:100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-content: center;
                .line{
                    // position: absolute;
                    // top:0;
                    // left: calc(50vw - 0.25rem);
                    width:0.5rem;
                    background-color: $col-dark;
                    height: 0;
                    // max-height: 30vh;
                    border-radius: 0 0 0.5rem 0.5rem;
                    transition: all 0.3s;
                }
            }
            
     

            .main-column-content{
                align-self: center;
            }

            .content{   
                display:flex;
                flex-direction: column;
                align-content: center;
                transition:all 1s;
                // padding-top: 30vh;
                // padding-bottom: 30vh;

               
            }


            h1{
                text-align: center;
            }

            p{
                text-align: center;
            }
        }

        

    }



   
}
#contact.hidden .content{
    opacity: 0;
}
#contact.revealed .content{
    opacity: 1;
    transform: translateY(0px);
}