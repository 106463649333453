@use '../../assets/styles/_colors.scss' as *;
@use '../../assets/styles/_vars.scss' as *;
@use '../../assets/styles/_breakpoints.scss' as *;

.bizcard{
    display:flex;
    flex-direction:row;
    margin:4rem;
    transition: all 0.3s;
    position: relative;
    max-width: 35rem;
    align-self: center;
    // border:1px solid green;
    // background-color: $col-light;
    // box-shadow:0 1rem 18rem #2fc8eb11;
    // border-radius: 0.5rem;
    
    @include bp(s-down){
        flex-direction:column;
        margin:4rem 1rem;
    }

    .pic{
        
        display:inline-block;
        // flex-direction: row;
        // align-content: center;
        // justify-content: center;
        position: relative;
        text-align: center;

        .container{
            position: relative;
            
            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: center
            ;

        }

        .pic-and-bubble{
            align-self: center;
            position: relative;
        }

        @include bp(s){
            width: 100%;
            margin-bottom: 2rem;
            
        }
        .bubble{
            display:none;
            position:absolute;
            background-color: $col-yellow;
            color:$col-dark;
            // top: -2rem;
            bottom: 6rem;
            // left:-0.5rem;
            right: calc(100% - 1.5rem);
            padding:1rem 2rem;
            font-family: $font-body;
            font-size: $font-size-body;
            font-weight: 700;
            border-radius: 2rem 2rem 0 2rem;
            z-index:100;
            // overflow: hidden;
            // white-space: nowrap;
            transition: all 0.3s cubic-bezier(0.175, 0.885, 0.320, 1.275);
            transform-origin:100% 100%;
            transition-delay:0.5s;
            width: max-content;
            max-width: 30vw;

            em{
                font-style: normal;
                border-bottom:2px solid $col-dark-wraith;
            }
            
            @include bp(s-down){
                max-width: 22vw;
                font-size: $font-size-body-xs;
            }

            @include bp(m-down){
                max-width: 18vw;
            }
        }
        
        img{
            width: 8rem;
            height: 8rem;
            
        }
    }

    .pic.with-bubble{
        // padding-left: 2rem;
        .bubble{
            display:block;
        }
    }

    .text{
        display: flex;
        flex-direction: column;
        margin-left: 2rem;
        width:100%;
        align-self: center;
        // border:1px solid green;
        
        @include bp(s-down){
            margin-left: 0;
        }
        
        .name{
            margin:0;
            color:$col-dark;
            font-weight: 600;
            text-align: left;
            @include bp(s-down){
                text-align: center;
            }
        }
        .roles{
            margin:0;
            margin-top:0.2rem;
            color: $col-dark;
            opacity:0.7;
            text-align: left;
            @include bp(s-down){
                text-align: center;
            }
        }

        p{
            // margin-top: 2rem;
            font-size: $font-size-body;
            text-align: left;
            color:$col-dark;
            // border:1px solid red;
        }


        
    }
}

.bizcard.light{
    .text{
        h3,h4,p{
            color:$col-light;
        }
    }
}

.bizcard.eggshell{
    .text{
        h3,h4,p, span{
            color:$col-eggshell !important;
        }
    }
}

.bizcard.in-view{
    opacity: 1;
    transform: translateY(0);

    .bubble{
        transform: scale(1) translateY(0);
        opacity:1;
    }

}

.bizcard.out-of-view{
    opacity: 0;
    transform: translateY(3rem);
    .bubble{
        transform: scale(0.1) translateY(2rem);
        transition-delay:0s;
        opacity:0;
    }
}

.bizcard.pos-top.out-of-view{
    transform: translateY(-3rem);
}