// -----------------------
// FONT FAMILIES
$font-button: "Poppins", "Avenir", Helvetica, Arial, "_sans";
$font-header: "Poppins", "Avenir", Helvetica, Arial, "_sans";
$font-italic: "Poppins", "Avenir", Helvetica, Arial, "_sans";
$font-header-italic: $font-italic;
$font-body: "Poppins", "Avenir", Helvetica, Arial, "_sans";
$font-body-italic: $font-body;
$font-snack: $font-header;


// -----------------------
// FONT SIZE
$font-size-body-xxl:2.2rem;
$font-size-body-xl:1.5rem;
$font-size-body-l:1.2rem;
$font-size-body:1.05rem;
$font-size-body-small:1rem;
$font-size-body-xs:0.9rem;
$font-size-body-xxs:0.75rem;
$font-size-body-smallcaps:0.9rem;
$font-size-body-xsmallcaps:0.75rem;




$max-readable-width: 800px;

